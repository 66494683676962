import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import moment from 'moment'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
const dateSyle = require('react-datepicker/dist/react-datepicker.css')

import {
  ButtonContainer,
  LogoContainer,
  LogoImage,
  TitleStore,
  ModalFooter,
  CustomInput,
  ButtonReservation
} from './style'
import { StyledError } from '../../../Collapse/stepped/style'
import { SubmitButton } from '../../../StylesPages/loginStyle'
import { GtmEventsLoad } from '../../../../helpers/gtm'
import { ReservationEvent } from '../../../../helpers/GTM/ReservationEvent'
import { validateIdentification } from '../../../../utils/validateIdentification'

export default class Reservation extends Component<
  { restaurant: any; fields: any ;customerId: number; identification: string; closeModal: any },
  any
  > {
  constructor(props: any) {
    super(props)
    this.state = {
      subsidiary: {},
      fields: props.fields,
      errors: {},
      personNumber: '1',
      rangeTime: [],
      disabled: false,
      subsidiaries: this.loadSubsidiaries(),
      customerId: null,
      isCustomer: false
    }
    registerLocale('es', es)
    this.sendReservation = this.sendReservation.bind(this)
    this.handleDate = this.handleDate.bind(this)
    this.handleTime = this.handleTime.bind(this)
    this.handlePersonNumber = this.handlePersonNumber.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  notify = (message: string, type?: string) => {
    if (type === 'error') {
      return toast.error(message)
    }
    return toast(message)
  }

  checkFields() {
    let fields = this.state.fields
    let errors: any = {}
    let formIsValid = true
    if (!fields['identification']) {
      formIsValid = false
      errors['identification'] = 'El número de identificación es requerido.'
    } else {
      const validIdentification = validateIdentification(fields['identification'], 'C')
      if (validIdentification.error){
        errors['identification'] = validIdentification.message
        formIsValid = false
      }
    }

    if (!fields['startDate']) {
      formIsValid = false
      errors['startDate'] = 'El día de la reservación es requerido'
    }
    if (!fields['startTime']) {
      formIsValid = false
      errors['startTime'] = 'La hora de la reservación es requerida'
    }
    if (!fields['mobile']) {
      formIsValid = false
      errors['mobile'] = 'El número de celular es requerido'
    }
    if (fields['mobile']) {
      const value = fields['mobile']
        .replace('(', '')
        .replace(')', '')
        .replace(/\_/gi, '')
      if (value.length >= 1 && value.length < 10) {
        formIsValid = false
        errors['mobile'] = 'El número de celular está incompleto'
      }
    }
    if (!this.props.customerId) {
      if (!fields['name']) {
        formIsValid = false
        errors['name'] = 'El nombre es requerido'
      }
      if (/[^ñáéíóúa-z _]/i.test(fields['name'])) {
        formIsValid = false
        errors['name'] = 'El nombre solo debe contener letras.'
      }
      if (!fields['lastName']) {
        formIsValid = false
        errors['lastName'] = 'El apellido es requerido'
      }
      if (/[^ñáéíóúa-z _]/i.test(fields['lastName'])) {
        formIsValid = false
        errors['lastName'] = 'El apellido solo debe contener letras.'
      }
      if (!fields['email']) {
        formIsValid = false
        errors['email'] = 'El correo electrónico es requerido.'
      } else if (!/\S+@\S+\.\S+/.test(fields['email'])) {
        formIsValid = false
        errors['email'] = 'El correo electrónico es incorrecto.'
      }
    }
    this.setState({ errors })
    return formIsValid
  }

  sendReservation(event: any) {
    event.preventDefault()
    const { personNumber, fields, subsidiary, disabled, customerId } = this.state
    const { restaurant } = this.props
    if (disabled) {
      return
    }

    this.setState({
      disabled: true
    })

    if (!this.checkFields()) {
      this.notify('Formulario Incompleto', 'error')
      this.setState({
        disabled: false
      })
      return
    }

    const reservationData = this.processDataBeforeSend({
      persons_number: personNumber,
      reservation_date: `${moment(fields['startDate']).format('YYYY-MM-DD')} ${
        fields['startTime'].value
        }`,
      mobile: fields['mobile'],
      special_request: fields['specialRequest'],
      subsidiaries: subsidiary.id || this.props.restaurant.subsidiaries[0].id,
      customer_id: customerId,
      identification: fields['identification'],
      name: `${fields['name']}oyster-fest`,
      lastName: fields['lastName'],
      email: fields['email']
    })
    GtmEventsLoad(new ReservationEvent('checkout', 'checkout', 'reservation').initialize({item: restaurant, data: reservationData}))

    axios
      .post(`${process.env.API_URL}reservations`, reservationData, {})
      .then(() => {
        GtmEventsLoad(new ReservationEvent('purchase', 'purchase', 'reservation').initialize({item: restaurant, data: reservationData}))
        this.props.closeModal('confirmation')
      })
      .catch((error: any) => {
        this.setState({
          disabled: false
        })
        if (error.response) {
          this.notify(error.response.data.errorMessage, 'error')
          return
        }
        this.notify('Error al enviar el formulario de reserva', 'error')
      })
  }

  processDataBeforeSend(data: any) {
    if (data.customer_id) {
      return {
        persons_number: data.persons_number,
        reservation_date: data.reservation_date,
        mobile: data.mobile,
        special_request: data.special_request,
        subsidiaries: data.subsidiaries,
        customer_id: data.customer_id
      }
    }
    return data
  }

  loadSubsidiaries(): any[] {
    return this.props.restaurant.subsidiaries.map((subsidiary: any) => ({
      value: subsidiary.id,
      label: subsidiary.alias
    }))
  }

  handleSelect = (selectedSubsidiary: any) => {
    this.setState({
      subsidiary: this.props.restaurant.subsidiaries.filter(
        (subsidiary: any) => subsidiary.id == selectedSubsidiary.value
      )[0],
      fields: {},
      errors: {},
      rangeTime: []
    })
  }

  handleDate(selectedDate: any) {
    axios
      .post(
        `${process.env.API_URL}reservations/subsidiaries/${this.state.subsidiary.id}/schedules`,
        { selectedDay: this.formatDate(selectedDate) },
        {}
      )
      .then((data: any) => {
        const { fields } = this.state
        fields['startDate'] = selectedDate
        if (data.data.schedules.length == 0) {
          this.setState({
            errors: { startTime: data.data.scheduleMessage }
          })
          return
        }
        let rangeDate = data.data.schedules
        const now = moment();
        const thirtyMinutesToNow = now.add(0, 'h').add(30, 'm');
        const today = moment(selectedDate).toDate()
        const checkIsToday = moment(today.getDate()).isSame(now.toDate().getDate())

        if (checkIsToday) {
          rangeDate = [...data.data.schedules].filter((item) => {
            if (thirtyMinutesToNow.isBefore(moment(item, "HH:mm"))) {
              return item
            }
          })
        }
        this.setState({
          fields: { ...fields },
          errors: {},
          rangeTime: rangeDate
        })
      })
      .catch(() => {
        this.notify('Error al cargar los horarios para esta sucursal', 'error')
      })
  }

  private formatDate = (date: any) => {
    let accurateDate = new Date(date),
      month = `${accurateDate.getMonth() + 1}`,
      day = `${accurateDate.getDate()}`,
      year = `${accurateDate.getFullYear()}`

    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }

    return [day, month, year].join('-')
  }

  handleTime(time: any) {
    const { fields } = this.state
    fields['startTime'] = time
    this.setState({
      fields: { ...fields }
    })
  }

  handleUpdate = (field: string, event: any) => {
    const fields = this.state.fields
    fields[field] = event.target.value
    this.setState({ fields })
  }

  checkCustomer = (identification: string) => {
    axios
      .get(`${process.env.API_URL}reservations/check-customer`, {
        params: { identification }
      })
      .then(response => response.data)
      .then(data => {
        const { customerId, identification, name, lastName, email, phone} = data
        if (customerId) {
          this.setState({ customerId: data.customerId, fields: { identification, name, lastName, email, mobile: phone }, isCustomer: true })
          return
        }
        this.setState({ isCustomer: false })
      })
  }

  handlePersonNumber(number: any) {
    this.setState({
      personNumber: number.value
    })
  }

  componentDidMount() {
    this.setState({
      subsidiary: this.props.restaurant.subsidiaries[0]
    })
  }

  fillRangeTime(timePeriods: any, selectedDate: any) {
    let today = new Date()
    let isToday =
      selectedDate.getDate() == today.getDate() &&
      selectedDate.getMonth() == today.getMonth() &&
      selectedDate.getFullYear() == today.getFullYear()

    if (isToday && timePeriods.length > 0) {
      let nearestMinute = this.roundToNearestMinute(today)
      let twoHoursPlus = `${today.getHours() + (nearestMinute == 0 ? 7 : 2)}:${
        nearestMinute == 0 ? '00' : nearestMinute
        }`
      const position = timePeriods.findIndex((period: any) => period == twoHoursPlus)
      let trimmedPeriods = []
      for (let i = 0; i < timePeriods.length; i++) {
        if (i > position - 1) {
          trimmedPeriods.push(timePeriods[i])
        }
      }
      return [trimmedPeriods]
    }
    return timePeriods
  }

  roundToNearestMinute(today: any) {
    let quarterHour = Math.floor(today.getMinutes() / 15)
    if (today.getMinutes() % 15 != 0) {
      quarterHour++
      if (quarterHour == 4) {
        quarterHour = 0
      }
      quarterHour = quarterHour * 15
      return quarterHour
    }
  }

  render() {
    const { restaurant, customerId } = this.props
    const { fields, errors, disabled, rangeTime, subsidiaries, isCustomer } = this.state
    return (
      <div>
        <div className="gt-reservation row">
          <div className="col-md-9">
            <div className="container">
              <div className="row header">
                <div className="display-desktop">
                  <LogoContainer>
                    <LogoImage src={restaurant.icon_b} alt={'logo-' + restaurant.name + '-Grande Table'} />
                  </LogoContainer>
                </div>
                <div className="col-md-8 col-lg-10">
                  <TitleStore>{restaurant.name ? restaurant.name.toLowerCase() : ''}</TitleStore>
                  {restaurant.subsidiaries.length > 1 && (
                    <div className="select-control">
                      <div>Selecciona el local donde quieres realizar la reserva:</div>
                      <Select
                        isSearchable={false}
                        onChange={this.handleSelect}
                        options={subsidiaries}
                        defaultValue={subsidiaries[0]}
                        className="select-control-container"
                        classNamePrefix="select-control"
                      />
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row direccion-wrapper">
                        <div className="address-label">
                          <i className="icon-grande-table-26" style={{ fontSize: '18px' }} />
                          <strong>Dirección:</strong>
                        </div>
                        <div className="address-text">{this.state.subsidiary.address}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`row ${!customerId ? '' : 'no-extra-fields'}`}>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label className="control-label">Identificación</label>
                    <CustomInput
                      className={`input-st ${errors['identification'] ? 'error' : ''}`}
                      placeholder="Ingresa tu número de cédula"
                      onChange={this.handleUpdate.bind(this, 'identification')}
                      onBlur={(e) => this.checkCustomer(e.target.value)}
                      value={fields['identification']}
                    />
                    {errors['identification'] && <StyledError>{errors['identification']}</StyledError>}
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="control-label">Nombre</label>
                    <CustomInput
                      disabled={isCustomer}
                      className="form-control"
                      placeholder="Ingresa tu nombre"
                      onChange={this.handleUpdate.bind(this, 'name')}
                      value={fields['name']}
                    />
                    {errors['name'] && <StyledError>{errors['name']}</StyledError>}
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="control-label">Apellido</label>
                    <CustomInput
                      disabled={isCustomer}
                      className="form-control"
                      placeholder="Ingresa tu apellido"
                      onChange={this.handleUpdate.bind(this, 'lastName')}
                      value={fields['lastName']}
                    />
                    {errors['lastName'] && <StyledError>{errors['lastName']}</StyledError>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`col-md-12 ${!customerId ? 'col-lg-6' : 'col-lg-6'}`}>
                  <div className="form-group mask-control">
                    <label className="control-label">Celular</label>
                    <InputMask
                      disabled={isCustomer}
                      mask="(99)99 999 999"
                      onChange={this.handleUpdate.bind(this, 'mobile')}
                      className="form-control styled-mask"
                      value={fields['mobile']}
                      placeholder="Ingresa un número celular"
                    />
                    {errors['mobile'] && <StyledError>{errors['mobile']}</StyledError>}
                  </div>
                </div>
                <div className={!customerId ? 'col-md-12 col-lg-6' : 'no-extra-fields'}>
                  <div className="form-group mask-control">
                    <label className="control-label">Correo electrónico</label>
                    <input
                      disabled={isCustomer}
                      name="email"
                      value={fields['email'] || ''}
                      className={`input-st email-width ${errors['email'] ? 'error' : ''}`}
                      type="text"
                      onChange={this.handleUpdate.bind(this, 'email')}
                      placeholder="Ingresa un correo electrónico"
                    />
                    {errors['email'] && (
                      <StyledError className="email-lbl-err">{errors['email']}</StyledError>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="form-group">
                    <label className="control-label">Día</label>
                    <DatePicker
                      selected={fields['startDate']}
                      onChange={this.handleDate}
                      minDate={new Date()}
                      maxDate={new Date("25-09-2022")}
                      todayButton={'Hoy'}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="dd-mm-aaaa"
                      error={errors['startDate']}
                      className="datepicker-style"
                      locale="es"
                    />
                    <i className="icon-grande-table-24" />
                  </div>
                  {errors['startDate'] && <StyledError>{errors['startDate']}</StyledError>}
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="form-group select-control">
                    <label className="control-label">Hora</label>
                    <Select
                      isSearchable={false}
                      isDisabled={fields['startDate'] ? false : true}
                      onChange={this.handleTime}
                      error={errors['startTime']}
                      value={fields['startTime']}
                      className="select-control-container"
                      classNamePrefix="select-control"
                      options={[].concat
                        .apply([], rangeTime)
                        .map((hour: any) => ({ value: hour, label: hour }))}
                    />
                  </div>
                  {errors['startTime'] && <StyledError>{errors['startTime']}</StyledError>}
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="form-group select-control">
                    <label className="control-label"># Personas</label>
                    <Select
                      isSearchable={false}
                      className="select-control-container"
                      classNamePrefix="select-control"
                      onChange={this.handlePersonNumber}
                      options={(() => {
                        const options = []
                        for (let i = 1; i <= 10; i++) {
                          options.push({ value: i, label: i })
                        }
                        return options
                      })()}
                      defaultValue={{ label: 1, value: 1 }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="control-label">Solicitud especial</label>
                    <CustomInput
                      className="form-control"
                      placeholder="Opcional"
                      onChange={this.handleUpdate.bind(this, 'specialRequest')}
                      value={fields['specialRequest']}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <ButtonReservation>
                    <ButtonContainer>
                      <SubmitButton
                        id="GTABLE_REST_FIN"
                        type="button"
                        value={disabled ? 'Reservando...' : 'Realizar Reserva'}
                        onClick={this.sendReservation}
                        disabled={disabled}
                      />
                    </ButtonContainer>
                  </ButtonReservation>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="hint-text">
              <div className="hint-title">IMPORTANTE:</div>
              <p>
                Para garantizar tu reserva es importante que te encuentres en el restaurante en la
                hora requerida. En caso de no estar, podremos hacer uso de tu reserva.
              </p>
              <p>Recuerda que únicamente puedes hacer reservas con 2 horas de anticipación.</p>
              <p>
                Reserva sujeta a disponibilidad. Se enviará un correo con la confirmación de la
                reserva.
              </p>
            </div>
            <div className="hint-text">
              <div className="hint-title">RESTRICCIONES:</div>
              <ul>
                {restaurant.restrictions.map((restriction: string, index: number) => (
                  <li key={`restriction-${index}`}>{restriction}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <ModalFooter>Recibirás un correo con la confirmación de tu reserva.</ModalFooter>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          draggable={true}
          pauseOnHover
        />
      </div>
    )
  }
}